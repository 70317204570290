import { keys } from 'lodash-es';
import { readable } from 'svelte/store';

export const breakpointMatcher = <TMatches extends string>(
	mediaQueries: Record<TMatches, MediaQueryList | string>,
) => {
	if (typeof window === 'undefined') return readable(null); //return null if window doesn't exist

	return readable<TMatches | null>(null, (set, update) => {
		// extract the breakpoints
		const mediaQueryUnsubscribeList: Array<() => void> = [];

		// convert sizes to <string, MediaQueryList>
		// this is converted here because of window undefined issue
		keys(mediaQueries).forEach((size) => {
			const sizeMatch = size as TMatches;

			const matchedMedia = window.matchMedia(mediaQueries[sizeMatch] as TMatches);

			function handleChange(e: MediaQueryListEvent) {
				update((currentSize) => {
					if (e?.matches) {
						return sizeMatch;
					} else if (currentSize === size) {
						return null;
					}

					return currentSize;
				});
			}

			if (matchedMedia.matches) set(sizeMatch);

			try {
				matchedMedia.addEventListener('change', handleChange);
			} catch (_) {
				matchedMedia.addListener(handleChange);
			}

			mediaQueryUnsubscribeList.push(() => {
				try {
					matchedMedia.removeEventListener('change', handleChange);
				} catch (_) {
					matchedMedia.removeListener(handleChange);
				}
			});
		});

		return () => {
			mediaQueryUnsubscribeList.forEach((unsubscribe) => {
				unsubscribe();
			});
		};
	});
};
