import { breakpointMatcher } from '$lib/utilities/mediaQuery/breakpointMatcher';

const baseQueries = {
	xs: '(max-width: 399px)',
	sm: '(min-width: 400px) and (max-width: 767px)',
	md: '(min-width: 768px) and (max-width: 1023px)',
	lg: '(min-width: 1024px) and (max-width: 1279px)',
	xl: '(min-width: 1280px) and (max-width: 1535px)',
	'2xl': '(min-width: 1536px)',
};
export const baseMediaQuery = breakpointMatcher(baseQueries);

export type BaseMediaQueryBreakpointKeys = keyof typeof baseQueries | null;
